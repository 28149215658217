export const WORDS = [
  'anise',
  'apple',
  'aspic',
  'bacon',
  'bagel',
  'basil',
  'beans',
  'bland',
  'bread',
  'broil',
  'candy',
  'cater',
  'chard',
  'chili',
  'chips',
  'cream',
  'crepe',
  'crisp',
  'crust',
  'curds',
  'curry',
  'dairy',
  'dates',
  'diner',
  'dough',
  'dried',
  'drink',
  'feast',
  'flour',
  'fried',
  'fruit',
  'grain',
  'grape',
  'gravy',
  'guava',
  'herbs',
  'honey',
  'icing',
  'jelly',
  'juice',
  'kebab',
  'knife',
  'ladel',
  'lemon',
  'liver',
  'lunch',
  'maize',
  'mango',
  'melon',
  'mints',
  'mochi',
  'munch',
  'olive',
  'onion',
  'order',
  'oreos',
  'pasta',
  'patty',
  'peach',
  'pecan',
  'pilaf',
  'pizza',
  'plate',
  'prune',
  'punch',
  'roast',
  'salad',
  'salsa',
  'sauce',
  'seeds',
  'slice',
  'snack',
  'spicy',
  'spoon',
  'spork',
  'spuds',
  'squid',
  'steak',
  'stove',
  'straw',
  'sugar',
  'sushi',
  'sweet',
  'syrup',
  'thyme',
  'toast',
  'torte',
  'tuber',
  'wafer',
  'water',
  'wheat',
  'yeast'
]
